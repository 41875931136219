import { DateFormatter } from '../../blocks/common/date-formatter';
import { isMembersInstalled } from '../../blocks/common/members';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import blocksModel from './model';
import type { ControllerParams, IController } from './types';

export class MetadataController implements IController {
  private readonly facade = new BlocksWidgetFacade(
    this.context.flowAPI,
    this.context.controllerConfig.wixCodeApi,
  );
  private readonly dateFormatter = new DateFormatter(
    this.context.controllerConfig.wixCodeApi,
  );
  private readonly ui = (() => {
    const $w = this.context.$w;

    return {
      writer: $w('#text1') as $w.Text,
      publishedDate: $w('#text5') as $w.Text,
      timeToRead: $w('#text2') as $w.Text,
      lastPublishedDate: $w('#text3') as $w.Text,
      avatar: $w('#avatar') as $w.WutAvatar,
    };
  })();

  constructor(private readonly context: ControllerParams) {}

  pageReady = () => {};

  exports = {
    ...blocksModel.getExports(),

    initialize: async (model: PostPageRenderModel) => {
      this.setState(model);

      const membersInstalled = await isMembersInstalled(
        this.context.flowAPI.controllerConfig.wixCodeApi,
      );

      if (membersInstalled) {
        this.ui.writer.onClick(() =>
          this.facade.navigateToAuthorProfile(model.post),
        );
      }
    },
  };

  private setState({ post, author }: PostPageRenderModel) {
    this.ui.writer.text = post.owner?.name ?? '';

    if (!post.firstPublishedDate) {
      throw new Error('No first published date provided');
    }

    this.ui.publishedDate.text = this.dateFormatter.format(
      post.firstPublishedDate,
    );

    this.ui.timeToRead.text = `${post.timeToRead} min read`;

    if (post.lastPublishedDate) {
      this.ui.lastPublishedDate.text = `Updated: ${
        this.dateFormatter.formatICU(post.lastPublishedDate) ?? ''
      }`;
    }

    this.ui.avatar.firstName = author.firstName;
    this.ui.avatar.lastName = author.lastName;
    this.ui.avatar.src = author.avatarUrl;
  }
}
